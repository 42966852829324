@import "../../../Style/Breakpoints";
.container{

  :global{

    .box{
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;
      padding:24px;
      border-radius: 8px;
      border: 1px solid var(--brown-110, #524848);
      background: var(--brown-80, #373131);
      box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
      @include lg(){
        gap: 32px;
        margin-bottom: 48px;
        height: 450px;
        min-height: 60vh;
        flex-direction: row;
      }
    }
    .upload-box{
      border-radius: 8px;
      border: 1px dashed var(--gray-100, #555);
      background: var(--brown-50, #342E2E);
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 352px;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 8px;
      color: var(--gray-90, #A6A6A6);
      font-size: 14px;
      font-style: normal;
    }

  }
}
