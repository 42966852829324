.container{
  border-radius: 8px;
  height: auto;
  border: 1px solid var(--brown-110, #524848);
  background: var(--brown-90, #423A3A);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  padding:16px;
  flex-direction: column;
  display:flex;
  :global{

  }
}
