@import "/src/Style/Breakpoints";
.container{



  :global{

    .crypto-grid-box{
      display: flex;
      flex-direction: column;
      gap: 12px;

      @include md(){
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(350px,.5fr));
        //grid-template-rows: repeat(auto-fill,minmax(300px,1fr));
        gap: 12px;
      }
    }
  }
}
