.container{


  :global{
    .faq-box{
      border-radius: 8px;
      border: 1px solid var(--brown-110, #524848);
      background: var(--brown-50, #342E2E);
      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
      &.opened{
        background: var(--brown-90, #423A3A);
        box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
      }

      .desc{
        overflow: hidden;
        max-height: 0;
        &.opened{
          transition: 1s;

          max-height: 1000px;
        }
      }
    }

  }
}
