//@import "/src/Style/Breakpoints";
.container{
  :global{
    .day_container{
      color: var(--Gray-90, #A6A6A6);
      border-right: 2px solid var(--Brown-50, #342E2E);
      @apply text-gray-90 border-brown-50 border-y-1 bg-brown-40 flex flex-col items-center px-2 py-2 ;
      &.passed{
        opacity: .4;
      }
      &.selected{
        background: var(--Gradient-white, linear-gradient(180deg, #FFF -56.58%, #D9D9D9 50%));
        color: var(--Brown-40, #262121);
      }
    }
    .main-calender{
      .tabs{
        .tab{
          border-radius: 8px;
          width: 50%;
          display: grid;
          padding: 12px 24px;

          place-items: center;
          &.active{
            color: var(--Brown-40, #262121);
            background: var(--Gray-50, #D9D9D9);
            box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.30);
          }
        }
      }
      .box{
        flex-direction: column;
        max-height: 100%;
        background: linear-gradient(180deg, #262121 0%, #2B2525 100%);
        border-radius: 8px;
        padding: 24px ;
        padding-bottom: 0;
       @media only screen and (min-width: 1100px){
          overflow: hidden;
          border-radius: 8px 8px 0px 0px;
        }
        .title{
          color: var(--Gray-50, #D9D9D9);
          font-size: 24px;
          font-weight: 600;
        }
      }
    }

    .rdp {
      --rdp-cell-size: 38px; /* Size of the day cells. */
      --rdp-caption-font-size: 18px; /* Font size for the caption labels. */
      --rdp-accent-color: white ; /* Accent color for the background of selected days. */
      --rdp-background-color: var(--brown-100, #453F3F); /* Background color for the hovered/focused elements. */
      --rdp-accent-color-dark: red; /* Accent color for the background of selected days (to use in dark-mode). */
      --rdp-background-color-dark: red; /* Background color for the hovered/focused elements (to use in dark-mode). */
      --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
      --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
      --rdp-selected-color: black; /* Color of selected day text */
    }
  }
}
