@font-face {
  font-family: "Plus Jakarta Display";
  src: url("PlusJakartaDisplay-Light.eot");
  src: url("PlusJakartaDisplay-Light.eot?#iefix") format("embedded-opentype"),
    url("PlusJakartaDisplay-Light.woff2") format("woff2"),
    url("PlusJakartaDisplay-Light.woff") format("woff"),
    url("PlusJakartaDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Plus Jakarta Display";
  src: url("PlusJakartaDisplay-Regular.eot");
  src: url("PlusJakartaDisplay-Regular.eot?#iefix") format("embedded-opentype"),
    url("PlusJakartaDisplay-Regular.woff2") format("woff2"),
    url("PlusJakartaDisplay-Regular.woff") format("woff"),
    url("PlusJakartaDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Plus Jakarta Display";
  src: url("PlusJakartaDisplay-Medium.eot");
  src: url("PlusJakartaDisplay-Medium.eot?#iefix") format("embedded-opentype"),
    url("PlusJakartaDisplay-Medium.woff2") format("woff2"),
    url("PlusJakartaDisplay-Medium.woff") format("woff"),
    url("PlusJakartaDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Plus Jakarta Display";
  src: url("PlusJakartaDisplay-Bold.eot");
  src: url("PlusJakartaDisplay-Bold.eot?#iefix") format("embedded-opentype"),
    url("PlusJakartaDisplay-Bold.woff2") format("woff2"),
    url("PlusJakartaDisplay-Bold.woff") format("woff"),
    url("PlusJakartaDisplay-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Domine";
  src: url("Domine-VariableFont_wght.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


/*@font-face {*/
/*  font-family: "Plus Jakarta Sans";*/
/*  src: url("PlusJakartaSans-Light.ttf");*/
/*  font-weight: 300;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: "Plus Jakarta Sans";*/
/*  src: url("PlusJakartaSans-Regular.ttf");*/
/*  font-weight: 400;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: "Plus Jakarta Sans";*/
/*  src: url("PlusJakartaSans-Medium.ttf");*/
/*  font-weight: 500;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: "Plus Jakarta Sans";*/
/*  src: url("PlusJakartaSans-Bold.ttf");*/
/*  font-weight: 600;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/
