.container{


  :global{
    .input{
      //height: 400px;
      min-height: 50px;
      max-height: 200px;
      overflow: auto;
      color: white;
      border-radius: 12px;
      outline: none;
      font-size: 16px;
    }
    .input[contenteditable] {
      display: inline-block;

    }
    .input[contenteditable]:empty::before {
      content: attr(data-placeholder);
      display: inline-block;
      color: var(--Gray-90, #A6A6A6);

    }
    .input[contenteditable]:empty:focus::before {
      content: attr(data-focused-advice);
    }
  }
}
