.container{

  background: var(--Brown-40, #262121);
  :global{

    .sessionBox{
      z-index: 100 !important;
      position: relative;
      border-radius: 8px;
      border: 1px solid var(--Brown-100, #453F3F);
      background: var(--Brown-50, #342E2E);
      padding: 16px;
      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
      .session-time{
        border-radius: 4px;
        border: 1px solid var(--Brown-110, #524848);
        background: var(--Brown-80, #373131);
        padding: 8px 4px;
      }
      .statusBtn{
        display: flex;
        width: 89px;
        height: 34px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 4px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        &.accepted{
          color: var(--Secondary-Green, #2BAC76);
          background: rgba(43, 172, 118, 0.15);
        }
        &.pending{
          color: var(--Gray-90, #A6A6A6);
          background: rgba(2, 2, 2, 0.15);
        }
        &.rejected{
          color: var(--Secondary-Red, #F04438);
          background: rgba(240, 68, 56, 0.15);
        }
      }
    }
    .tabs{
      .tab{
        border-radius: 8px;
        border: 2px solid var(--Brown-90, #423A3A);
        background: var(--Brown-100, #453F3F);
        display: flex;
        height: 36px;
        padding: 8px;
        align-items: center;
        gap: 8px;
        color: var(--Gray-90, #A6A6A6);

        font-weight: 600;
        &.active{
          color: var(--Gray-100, #555);

          border-radius: 8px;
          border: 2px solid var(--Brown-90, #423A3A);
          background: var(--Gray-50, #D9D9D9);
        }
      }
    }
  }
}
