.container{
  border-radius: 8px;
  border: 1px solid var(--brown-100, #453F3F);
  background: var(--brown-80, #373131);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  :global{
    .box{
      border-radius: 8px;
      border: 1px solid var(--brown-100, #453F3F);
      background: var(--brown-50, #342E2E);
      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    }
    .like-wrapper{
      cursor: pointer;
      border-radius: 6px;
      border: 1px solid var(--brown-110, #524848);
      background: rgba(66, 58, 58, 0.38);

      .svghover{
        display: none;
      }
      &:hover{

        border-radius: 6px;
        border: 1px solid var(--brown-110, #524848);
        background: var(--brown-50, #342E2E);
      }
    }

    .comments-dropdown{
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid var(--brown-110, #524848);
      background: var(--brown-50, #342E2E);
    }
  }
}
