@import "/src/Style/Breakpoints";
.pinnedTabs{

    background: #373131;
    display: flex;
    gap: 8px ;
    padding: 12px 0;
    width: 100%;
    overflow: auto;
    border-bottom: 1px solid #524848;
    border-top: 1px solid #524848;

    &::-webkit-scrollbar {
      height: 0;
    }


}
.chatPageWrapper{
    @include lg{
        display: grid;
        grid-template-columns: 360px 1fr ;
        grid-template-rows: 78px 1fr;
        gap: 2px;
        background: #423A3A;
    }
    :global{

        .left-side{
            .input-box{
                transition: .3s;
                .input-search , .cansel{
                    visibility: hidden;
                    opacity: 0;
                    transition: .3s;
                }
                &.opened{
                    position: absolute;
                    right: 23px;
                    width: 313px;
                    @media only screen and (max-width: 550px){
                        width: calc(100vw - 48px);
                    }
                    z-index: 3;
                    .input-search , .cansel{
                        visibility: visible;
                        opacity: 1;
                        transition-delay: .5s;

                    }
                }
            }
        }


            .chat-area-wrapper{
            .input-wrapper{
                //min-height: 80px;
                //overflow: hidden;
                //flex-grow: 1;
                //margin-top: auto;
                .chat-box{
                    border-radius: 8px;
                    border: 1px solid var(--Brown-110, #524848);
                    background: var(--Brown-50, #342E2E);
                }
                .inputBtnsWrapper{
                    &>*{
                        cursor: pointer;
                    }
                }

                .input{
                    //height: 400px;
                    min-height: 50px;
                    max-height: 300px;
                    overflow: auto;
                    color: white;
                    border-radius: 24px;
                    outline: none;
                    font-size: 16px;
                }
                .input[contenteditable] {
                    display: inline-block;

                }
                .input[contenteditable]:empty::before {
                    content: attr(data-placeholder);
                    display: inline-block;
                    color: var(--Gray-90, #A6A6A6);

                }
                .input[contenteditable]:empty:focus::before {
                    content: attr(data-focused-advice);
                }
            }
            .chat-area{

                &::-webkit-scrollbar-track {
                    padding-bottom: 20px;
                    margin-bottom: 20px;

                }

            }
        }
    }
}

