.container{

  :global{
    .limit-line-text{
      display: inline-block;
      @apply w-[100px] lg:w-[320px];
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }
}
