@import "../../Style/Breakpoints";
.container{
  :global{
    .time-input{
      &::-webkit-datetime-edit-second-field {
        background:#342E2E;
        color: transparent;
        margin-left: -4px;
        position: absolute;
        width: 1px;
      }
    }
    .box{
      @include md(){
        border-radius: 8px;
        border: 1px solid var(--Brown-110, #524848);
        background: var(--Brown-80, #373131);
        box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);

      }

      .right{
        border-radius: 8px;
        border: 1px solid var(--Brown-110, #524848);
        background: var(--Brown-80, #373131);
        box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);

      }

    }

    .rdp {
      --rdp-cell-size: 38px; /* Size of the day cells. */
      --rdp-caption-font-size: 18px; /* Font size for the caption labels. */
      --rdp-accent-color: white ; /* Accent color for the background of selected days. */
      --rdp-background-color: var(--brown-100, #453F3F); /* Background color for the hovered/focused elements. */
      --rdp-accent-color-dark: red; /* Accent color for the background of selected days (to use in dark-mode). */
      --rdp-background-color-dark: red; /* Background color for the hovered/focused elements (to use in dark-mode). */
      --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
      --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
      --rdp-selected-color: black; /* Color of selected day text */
    }

  }
}
