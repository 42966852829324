@import "../../Style/Breakpoints";
.container{

  :global{
    .video-container{
      display: grid;
      gap: 12px;
      @include lg{

        grid-template-columns: repeat( auto-fit , minmax(350px,.34fr));
      }
    }
  }
}
