@import "Style/Breakpoints";
@import "Style/fonts/style.css";
@import "Style/global";
@import "Style/vars";

.font-dis{
    font-family: Plus Jakarta Display;
}
html{
    @include vars;
}
body{
    font-family: 'Plus Jakarta Sans', sans-serif;
    overflow: hidden;
    color: white;
}
.pad-layout{
    padding-left: 24px;
    padding-right: 24px;
    @include lg{
        padding-right: 32px;
        padding-left: 32px;
    }

}

input,textarea{
    outline: none;
    background: #342E2E;
    /* Brown/110 */
    resize: none;
    border: 1px solid #524848;
    border-radius: 8px;
    color: #D9D9D9;
    padding: 12px;
}
::-webkit-scrollbar {
    width: 4px;

}

/* Track */
::-webkit-scrollbar-track {
    background: #524848;
    border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #A6A6A6;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: white;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}



.paginator-wrapper{
    @apply max-w-[400px] mx-auto;
}

.pagination {
    justify-content: center;
    display: flex;
    padding-left: 0;
    list-style: none;
    margin: 0;
}


.page-item .page-link {
    position: relative;
    display: block;
    margin: 0 1px;
    min-height: 40px;
    min-width: 40px;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    color: #A6A6A6;
    background: #262121;
    text-decoration: none;
}

.page-item a.page-link:hover {
    background-color: #524848;
}

.page-item.active .page-link {
    font-weight: 700;
    color: #D9D9D9;
    background-color: #453F3F;
}

.page-item.disabled .page-link {
    color: #D9D9D9;
    pointer-events: none;
    cursor: auto;
}
