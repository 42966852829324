@import "/src/Style/Breakpoints";
.container{
  position: fixed;
  inset: 0;
  z-index: 100;
  @apply h-screen   flex flex-col ;
  overflow: hidden;
  @include lg{
    display: grid;
    grid-template-columns: 292px 1fr;
    grid-template-rows: 1fr;
  }
  :global{

  }
}
