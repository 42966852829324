@import "/src/Style/Breakpoints";


.container{

  @include lg(){
    border: none;
    //height: 100%;
    flex-direction: column;
    display: flex;
    height:100vh;
    overflow: hidden;
    min-height: 680px;
    padding: 0;


  }


  :global{
    .nav-items{
      @include md(){
        flex-direction: column;
      }
    }
    .nav-item{
      @apply flex flex-col items-center text-gray-90 gap-1 text-12 font-normal;
      &.active{
        @apply text-white;
        svg path{
          stroke: white;
        }
      }
    }
    .desktop-nav{
      //max-height: 100vh;
      padding: 36px 36px 48px;
      @media only screen and (max-height: 850px){
      padding: 24px 32px;
      }

      .logo-top{
        @media only screen and (max-height: 850px){
          margin-bottom: 14px;
        }
      }
    }
    .main-nav{
      .soon-section{
        .title{
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .soon-badge{
          font-size: 12px;
          background: #373131;
          border-radius: 43px;
          padding: 4px 8px;
        }
        span{
          //opacity: 35%;
        }
        ul{
          //opacity: 35%;
        }
      }

    }
    .nav-section{
      display: flex;
      flex-direction: column;
      padding:16px 0;
      @media only screen and (max-height: 850px){
        padding:14px 0;
      }
      font-weight: 500;
      border-bottom: 1px solid rgba(166, 166, 166, 0.3);
      .not-activated{
        opacity:.4;
      }
      &:last-child{
        border-bottom: none;
      }
      .title{
        font-weight: 500;
        font-size: 14px;
        height: 16px;
        font-family: Plus Jakarta Display;
        margin-bottom: 26px;
      }
      ul{
        display: flex;
        flex-direction: column;
        gap: 12px;
        //overflow: auto;
        //max-height: 200px;

        li{
          display: flex;
          gap: 12px;
          font-size: 14px;
          padding: 7px 12px;
          align-items: center;
        }
      }

      .navActiveLink {
        color: var(--Brown-40, #262121);
        border-radius: 4px;
        background: var(--Gradient-white, linear-gradient(180deg, #FFF -56.58%, #D9D9D9 50%));

        .notif-badge{
          background: var(--Gray-90, #A6A6A6);
          border: 1px solid var(--Gray-90, #A6A6A6);
          color: var(--Brown-40, #262121);

        }
      }
      &.channel-center{
        .navActiveLink svg path {
          stroke: var(--Brown-40, #262121);
        }
      }
      &:not(.channel-center){
        .navActiveLink svg path {
          fill: var(--Brown-40, #262121);
        }
      }
    }



    .navActiveLinkMobile{
      .nav-item{
        color: white !important;
      }
     &:not(.fill){
       svg path{
         stroke: white;
       }
     }

      &.fill {
        svg path{
          fill: white;
        }
      }

    }
  }

}
