.content{
  :global{
    span{
      color: #0096D7;
      cursor: pointer;
    }


  }
}

.chatInfoBox{

  :global{
    ul{
      padding: 6px 0px;
    }
    ul li{
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      gap: 8px;
      font-size: 12px;
      cursor: pointer;
      color: #A6A6A6;
      padding: 10px 0;
      border-radius: 8px;

      &:hover{
        padding: 10px ;
        background: #524848;
        color: white;

      }
    }
  }
}
