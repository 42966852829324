.container{
  border-radius: 8px;
  border: 1px solid var(--brown-100, #453F3F);
  background: var(--brown-80, #373131);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  :global{

    .profile{

      .avatar-wrapper{
        width: 88px;
        height: 88px;
        flex-shrink: 0;
        border-radius: 100%;
        overflow: hidden;
        background: white;
      }

      .name{
        color: var(--primary-white, #FFF);
        font-size: 18px;
        font-weight: 500;
      }
      .role{
        border-radius: 4px;
        background: var(--brown-90, #423A3A);
        color: var(--gray-50, #D9D9D9);
        font-size: 16px;
        padding: 4px 12px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
}
