
$offset: 140;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
          dash $duration ease-in-out infinite,
          colors ($duration*1) ease-in-out infinite;
}

@keyframes colors {
  0% { stroke: #A6A6A6; }
  50% { stroke: rgba(166, 166, 166, 0.62); }
  100% { stroke: #A6A6A6; }

}

@keyframes dash {
  0% { stroke-dashoffset: $offset; }
  50% {
    stroke-dashoffset: $offset/4;
    transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform:rotate(450deg);
  }
}
