.container{

  :global{
    .tab{
      @apply text-gray-90 py-6 items-center h-full flex items-center gap-2 cursor-pointer;
      &.active{
        color: white;
        position: relative;
        &:before{
          content: "";
          width: 100%;
          height: 1px;
          bottom: 0;
          background: white;
          position: absolute;
          left: 0;
          right: 0;
          display: block;
        }
      }
    }
  }
}
