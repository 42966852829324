.container{

  :global{

    .tab{

      font-weight: 600;
      border-radius: 8px;
      padding:0 8px;
      color: var(--Brown-110, #524848);
      height: 36px;
      border: 2px solid var(--Brown-90, #423A3A);
      &.active{
        background: var(--Gray-50, #D9D9D9);
        color: var(--Gray-100, #555);

      }
    }
    .sessionBox{
      padding: 24px;
      @apply md:rounded-8;
      border: 1px solid var(--Brown-110, #524848);
      background: var(--Brown-50, #342E2E);

      /* Shadow new */
      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
      .time{
        border-radius: 4px;
        padding: 4px 8px;

        border: 1px dashed var(--Brown-110, #524848);
        background: var(--Brown-50, #342E2E);
        color: var(--Gray-50, #D9D9D9);
        font-size: 14px;
        font-weight: 500;
      }
    }

    .statusBtn{
      display: flex;
      width: 89px;
      height: 34px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      &.accepted{
        color: var(--Secondary-Green, #2BAC76);
        background: rgba(43, 172, 118, 0.15);
      }
      &.pending{
        color: var(--Gray-90, #A6A6A6);
        background: rgba(2, 2, 2, 0.15);
      }
      &.rejected{
        color: var(--Secondary-Red, #F04438);
        background: rgba(240, 68, 56, 0.15);
      }
    }

  }
}
