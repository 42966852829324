.container{
  position:relative;

  :global{
    .content{
      position: relative;
      padding-right: 20px;
      h1{
        font-size: 64px;
      }
      h2{
        font-size: 48px;
      }
      h3{
        font-size: 32px;
      }
      h4{
        font-size: 24px;
      }
      h5{
        font-size: 20px;
      }
      *{
        margin-bottom: 10px;
      }
    }
    .content-title{
      color: var(--primary-white, #FFF);
      font-size: 18px;
      font-style: normal;
      margin-bottom: 12px;
      font-weight: 500;
      max-width: 400px;
    }
    .content-desc{
      color: var(--gray-50, #D9D9D9);
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 24px;
    }
    .img{
      max-width: 480px;
      min-height: 200px;
      background: #000;
      margin:24px 0;
      height:auto ;
      border-radius: 12px;
    }
  }
}
