.container{
  clip-path: polygon(0 0, 100% 0, 100% 20%, 100% 100%, 80% 100%, 10% 100%, 0 90%, 0% 20%);
  overflow: hidden;
  @apply  flex flex-col pb-14;
  :global{

    textarea{
      background: transparent;
      border: none;
      resize: none;
      outline: none;
      padding: 0;
      color: var(--Primary-Background, #0D0807);
      &::placeholder{
        color: black;
      }
    }
    .fliped-corner{
      .inner{
        clip-path: polygon(0 0, 0% 100%, 100% 100%);

      }
    }
  }
}
