.container{
  border-radius: 38px;
  border: 2px solid var(--brown-90, #423A3A);
  background: var(--brown-40, #262121);
  width: 42px;
  height: 24px;
  padding: 4px;
  position: relative;
  :global{

    .circle{
      width: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 2px;
      position: absolute;
      height: 15px;
      background: white;
      border-radius: 100%;
    }
  }
}
