@import "../../Style/Breakpoints";
.container{

  :global{


    .grid-mentors{

      display: grid;
      gap: 12px;
      @include md(){
        grid-template-columns: repeat(auto-fit,minmax(500px,.35fr));
        grid-template-rows: auto;
      }

    }
  }
}
