
@import "/src/Style/Breakpoints";


.container{
  @apply  overflow-hidden lg:overflow-auto flex flex-col ;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  height: 100svh;

  @include lg{
    display: grid;
    grid-template-columns: 292px 1fr;
    grid-template-rows: 1fr;
  }

  :global{
        .main{
          @include lg{
          grid-column-start: 2;
        }
      }
  }

}
