.container{

  flex-direction: column;
  height: 100%;
  display:flex;
  :global{
    .drafts-wrapper{
        display: grid;
        gap: 12px;
        grid-template-columns: repeat( auto-fit , minmax(400px,1fr));
    }
  }
}
