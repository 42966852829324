@import "/src/Style/Breakpoints";

.container{

  :global{
    .courses-container{
      display: grid;
      gap: 12px;
      @include md(){
        grid-template-columns: repeat( auto-fit , minmax(350px,.34fr));
      }
    }
    //.tab{
    //  @apply text-gray-90 py-6 cursor-pointer items-center h-full flex items-center gap-2;
    //  &.active{
    //    color: white;
    //    position: relative;
    //    &:before{
    //      content: "";
    //      width: 100%;
    //      height: 1px;
    //      bottom: 0;
    //      background: white;
    //      position: absolute;
    //      left: 0;
    //      right: 0;
    //      display: block;
    //    }
    //  }
    //}
  }
}
