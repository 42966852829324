.container{

  :global{

    .title{
      @apply text-24
    }
    .form{
      @apply  flex-col gap-5 flex;
      .label{
        @apply font-bold text-14
      }
      .input-box{
        @apply flex flex-col gap-4 text-14;
      }
      .submit-btn{
        @apply bg-gray-50 grid place-items-center rounded-10 h-12 text-black
      }

    }
  }
}
