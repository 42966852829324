.container{


  :global{
    .drop-down{
      border-radius: 8px;
      border: 1px solid var(--brown-110, #524848);
      background: var(--brown-50, #342E2E);
      display: flex;
      gap: 16px;
      padding: 8px 12px;
      color: var(--gray-90, #A6A6A6);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
    .transaction-grid{
      display: grid;
      border-bottom: 1px solid var(--brown-110, #524848);
      grid-template-columns: 1.3fr 450px 1fr 1.2fr;
      &:last-child{
        border-bottom: none;
      }
      .el{
        padding: 24px;
      }

    }
  }
}
