.container{


  :global{

    .box{
      border-radius: 8px;
      border: 1px solid var(--Brown-110, #524848);
      background: var(--Brown-80, #373131);
      box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    }

  }
}
