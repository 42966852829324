.container{

  :global{

    .edit-mentor-box{
      border-radius: 8px;
      border: 1px solid var(--brown-100, #453F3F);
      background: var(--brown-80, #373131);

      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

      .text-box{
        border-radius: 8px;
        padding: 12px;
        border: 1px solid var(--brown-110, #524848);
        background: rgba(66, 58, 58, 0.38);
      }
    }
  }
}
