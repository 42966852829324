.container{

  :global{

    .choose-time-box{
      border-radius: 8px;
      border: 1px solid var(--brown-100, #453F3F);
      background: var(--brown-80, #373131);
      /* Shadow new */
      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);


      .rdp {
        --rdp-cell-size: 40px; /* Size of the day cells. */
        --rdp-caption-font-size: 18px; /* Font size for the caption labels. */
        --rdp-accent-color: white ; /* Accent color for the background of selected days. */
        --rdp-background-color: var(--brown-100, #453F3F); /* Background color for the hovered/focused elements. */
        --rdp-accent-color-dark: red; /* Accent color for the background of selected days (to use in dark-mode). */
        --rdp-background-color-dark: red; /* Background color for the hovered/focused elements (to use in dark-mode). */
        --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
        --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
        --rdp-selected-color: black; /* Color of selected day text */
      }

    }
  }
}
