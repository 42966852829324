

@import "/src/Style/Breakpoints";

.g-page-title{
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #D9D9D9;
  border-bottom: 2px solid var(--brown-110, #423A3A);
  background: var(--brown-50, #342E2E);
  @include lg(){
    font-size: 24px;
    line-height: 32px;
  }
}
.btn{
  font-weight: 500;
  border-radius: 8px;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  padding: 0 32px;
  @apply px-4 md:px-8;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--gray-50, #D9D9D9);
  border: 1px solid var(--brown-110, #524848);
  background: var(--brown-40, #262121);
  font-size: 14px;
  &:disabled{
    opacity: .7;
  }
  &:hover{
    border: 1px solid var(--brown-110, #524848);

    background: var(--brown-110, #524848);
  }
  &.red{
    border: 1px solid var(--secondary-red, #F04438);
    background: rgba(205, 37, 83, 0.12) !important;
  }
  &.dark{
    border: 1px solid var(--brown-100, #453F3F);
    background: var(--brown-40, #262121);
  }
  &.transparent{
    background: transparent;
  }
  &.normal{
    border: 1px solid var(--brown-110, #524848);
    background: var(--brown-90, #423A3A);
    color: var(--gray-50, #D9D9D9);
  }
  &.grey-50{
    border: 1px solid var(--gray-50, #D9D9D9);
    background: var(--gray-50, #D9D9D9);
    border: 1px solid var(--brown-110, #524848);

    color: var(--primary-background, #0D0807);
  }
  &.grey-90{
    color: var(--brown-40, #262121);
    background: var(--gray-90, #A6A6A6);
  }
  &.big{
    width: 100%;
    @include md(){
      width: 178px;
    }
  }
}

.pop-up{
  position: relative;
  border-radius: 12px;
  border: 1px solid var(--Brown-110, #524848);
  background: var(--Brown-50, #342E2E);
  .close{
    position: absolute;
    cursor: pointer;
    top: 24px ;
    right: 24px;
  }
}
.role{
  border-radius: 4px;
  background: var(--brown-90, #423A3A);
  padding: 4px 12px;

}
.bordered-tab{
  border-radius: 4px;
  padding: 4px;
  border: 1px solid var(--Brown-110, #524848);
  background: var(--Brown-90, #423A3A);
}
.upload-area{
  border-radius: 8px;
  border: 1px dashed var(--Gray-100, #555);
  background: var(--Brown-50, #342E2E);
  display: flex;
  padding: 16px 24px;
}

