.container{
  border-radius: 8px;
  border: 1px solid var(--brown-100, #453F3F);
  background: var(--brown-80, #373131);
  width: 100%;
  height: fit-content;
  /* Shadow new */
  padding: 24px 18px;
  &:hover{
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    border: 1px solid var(--brown-110, #524848);
  }
  :global{
    .image-container{
      border-radius: 6px;
      height: 180px;
      background: var(--brown-100);
      width: 100%;
      box-shadow: 0px 4.76879px 6.6763px 0px rgba(0, 0, 0, 0.09);
    }
    .btn{
      //border-radius: 8px;
      //display: flex;
      //padding: 0 32px;
      //height: 48px;
      //justify-content: center;
      //align-items: center;
      //gap: 8px;
      //color: var(--gray-50, #D9D9D9);
      //border: 1px solid var(--brown-110, #524848);
      //background: var(--brown-40, #262121);
      //font-size: 14px;
      //&:hover{
      //  background: var(--brown-110, #524848);
      //}
    }
  }
}
