.container{

  :global{


    .warning-box{
      border-radius: 8px;
      border: 1px solid var(--Brown-110, #524848);
      background: rgba(82, 72, 72, 0.49);
      box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    }
    .tabs{
      .tab{
        border-radius: 8px;
        width: 50%;
        display: grid;
        padding: 12px 24px;

        place-items: center;
        &.active{
          color: var(--Brown-40, #262121);
          background: var(--Gray-50, #D9D9D9);
          box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.30);
        }
      }
    }

    .subscription_modal{
      border-radius: 12px;
      border: 1px solid var(--Brown-100, #453F3F);
      background: var(--Brown-80, #373131);
      width: 720px;
      height: 590px;
      flex-shrink: 0;
      /* Shadow new */
      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    }


    .table{
      border-radius:8px;
      overflow: hidden;
      border: 1px solid var(--Brown-110, #524848);

      tr{
        height: 50px;
        border-bottom: 1px solid var(--Brown-110, #524848);
        white-space: nowrap;
        &:last-child{
          border-bottom: none !important;
        }
        td{
          padding: 0 16px;

        }
      }
    }
  }
}

.subscription_box{
  border-radius: 8px;
  border: 1px solid var(--Brown-90, #423A3A);
  font-weight: 500;
  background: var(--Brown-40, #262121);
  padding: 24px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  :global{
    ul{
      list-style-position: inside;
    }
    .list-item{
      padding-left: 20px;
      &::marker{
      }
      border-bottom: 1px solid #342E2E;
      padding: 8px 0;
      color: var(--Gray-50, #D9D9D9);
      font-size: 12px;
      font-weight: 400;
      list-style-type: disc;
    }
  }
}


