@import "/src/Style/Breakpoints";
.container{
  border-radius: 8px;
  border: 1px solid var(--brown-110, #524848);
  //width: 100%;
  width: fit-content;
  flex-direction: column;
  background: var(--brown-50, #342E2E);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  padding:32px 16px;
  @include md(){
    padding: 32px;
    //height: 605px;
    max-height: 80vh;
  }
  :global{
    .rdp {
      --rdp-cell-size: 38px; /* Size of the day cells. */
      --rdp-caption-font-size: 18px; /* Font size for the caption labels. */
      --rdp-accent-color: white ; /* Accent color for the background of selected days. */
      --rdp-background-color: var(--brown-100, #453F3F); /* Background color for the hovered/focused elements. */
      --rdp-accent-color-dark: red; /* Accent color for the background of selected days (to use in dark-mode). */
      --rdp-background-color-dark: red; /* Background color for the hovered/focused elements (to use in dark-mode). */
      --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
      --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
      --rdp-selected-color: black; /* Color of selected day text */
    }
    .first-line{
      .sqr{
        border-radius: 8px;
        padding: 10px;
        background: var(--brown-40, #262121);
      }
    }

    .main-content{
      .left{
        @include md(){
          width: 380px;
        }
      }
      .right{
        flex-grow: 1;
        border-radius: 8px;
        border: 1px solid var(--brown-100, #453F3F);
        background: var(--brown-80, #373131);
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
      }
      .input-box{
        display: flex;
        flex-direction: column;
        font-size: 14px;
        gap: 8px;

        input,textarea{
          width: 100%;
        }
      }

      .daybox{
        flex-grow: 1;
        width: 71px;
        color: var(--gray-50, #D9D9D9);
        gap: 8px;
        border-radius: 8px;
        border: 1px solid var(--brown-110, #524848);
      }
      .hour-box{
        border-radius: 8px;
        border: 1px solid var(--brown-110, #524848);
        background: rgba(82, 72, 72, 0.13);
        padding: 12px 24px;

      }
    }

  }
}
