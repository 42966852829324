.container{

  :global{
    .tabs{
      display: flex;
      padding-left: 32px;
      @apply gap-6 md:gap-12;
      .tab-item{
        @apply text-14 md:text-16;
        padding:24px 0px ;
        color: #A6A6A6;
        white-space: nowrap;
        cursor: pointer;
        &.active{
          color: #D9D9D9;
          position: relative;
          &:before{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 0;
            background: white;
          }
        }
      }
    }


    .info-inputs{
      .info-box{
        .title{
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #D9D9D9;
          margin-bottom: 12px;
        }
        input{
          height: 47px;
          width: 100%;
        }
      }
    }
  }
}
