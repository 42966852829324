.container{

  &:hover{
    :global{
      .dropdown{
        display: flex !important;
      }
    }
  }
  &::before{
    content: "";
    display: flex;
    width: 24px;
    height: 20px;
    z-index: 0;
    background: transparent;
    position: absolute;
    top: 20px;
    right: 0;
  }
  :global{
    .dropdown{
      display: none;
      border-radius: 8px;
      border: 1px solid var(--Brown-110, #524848);
      background: var(--Brown-110, #524848);
      position: absolute;
      top: 32px;
      padding: 4px;
      right: 0;
      flex-direction: column;
      gap: 6px;

      &>div{
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 4px;
        color: var(--Gray-90, #A6A6A6);
        /* Desktop/Tag */
        font-family: Plus Jakarta Display;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
        border-radius: 4px;
        min-width:90px;
        &:hover{
          background: var(--Brown-90, #423A3A);
          color: var(--Gray-50, #D9D9D9);
          svg path{
            stroke:  #D9D9D9;
          }
        }
      }
    }
  }
}
