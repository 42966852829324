.container{

  :global{

    .grid-container{
      display: grid;
      gap: 12px;
    }
  }
}
