@import "/src/Style/Breakpoints";

.container{
  border-radius: 8px;
  border: 1px solid var(--brown-110, #524848);
  background: var(--brown-80, #373131);
  display: flex;
  height: 100%;
  padding: 16px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  @include lg(){
    padding: 18px 18px 24px 18px;
  }
  :global{
    .time-badge{
      border-radius: 8px;
      background: rgba(38, 33, 33, 0.59);
      padding:4px 8px;
    }
  }
}
