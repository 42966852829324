.dropdown_filter{
  position: absolute;
  top: 120%;
  z-index: 120;
  padding: 8px 8px;
  border-radius: 8px;
  border: 2px solid var(--Brown-110, #524848);
  background: var(--Brown-90, #423A3A);
  box-shadow: 0px 3px 4px 0px rgba(49, 43, 43, 0.35);olor: var(--Gray-90, #A6A6A6);
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 4px;
  :global{
    .filter-item{
      text-align: left;
      border-radius: 4px;
      cursor: pointer;
      color: var(--Gray-90, #A6A6A6);
      padding: 8px 4px 8px 8px;
      &:hover{
        background: rgba(0, 0, 0, 0.08);
      }
      &.active{
        color: var(--Primary-White, #FFF);
        background: var(--Brown-80, #373131);

      }
    }
  }
}
