.container{
  flex-direction: column;
  height: 100%;
  display:flex;

  :global{
    .editor-wrapper{
      border-radius: 8px;
      border: 1px solid var(--brown-100, #453F3F);
      background: var(--brown-80, #373131);
      box-shaqdow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
      .label{
        color: var(--gray-50, #D9D9D9);
        font-weight: 500;
      }
      .btn{
        width: 249px;
      }
      .box-title{
        color: var(--gray-50, #D9D9D9);
        font-weight: 500;
        margin-bottom: 24px;
      }
      .upload-box{
        border-radius: 8px;
        border: 1px dashed var(--gray-100, #555);
        background: var(--brown-50, #342E2E);
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 352px;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 8px;
        color: var(--gray-90, #A6A6A6);
        font-size: 14px;
        font-style: normal;
      }
      .preview-box{
        width: 300px;
        height: 466px;
        padding: 24px 24px;
        border-radius: 8px;
        border: 1px solid var(--brown-100, #453F3F);
        background: var(--brown-80, #373131);
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        .cover{
          color: var(--brown-90, #423A3A);
          font-size: 32px;
          font-weight: 700;
          border-radius: 6px;
          background: var(--brown-50, #342E2E);
          box-shadow: 0px 4.76879px 6.6763px 0px rgba(0, 0, 0, 0.09);
        }
      }
    }



    .quill{
      .ql-stroke,.ql-fill,.ql-even{
        stroke: var(--gray-50, #D9D9D9);
      }
      .ql-stroke-miter{
        stroke: var(--gray-50, #D9D9D9);

      }
      .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before, .ql-snow .ql-picker.ql-header ::before {
        color:  var(--gray-50, #D9D9D9);
      }
      .ql-toolbar{
        border-radius: 16px 16px 0 0 ;
        border: 1px solid var(--brown-110, #524848);
        background: var(--brown-80, #373131);
      }
      .ql-container{
        border-radius:0 0 16px 16px  ;
        border: 1px solid var(--brown-110, #524848);
        background: var(--brown-80, #373131);
      }
    }
  }
}
