.container{
  border-radius: 32px 32px 0px 0px;
  background: linear-gradient(180deg, #000 0%, #262020 100%);
  padding:24px;
  transition: .3s;
  //box-shadow: 0 -1000px 0px 1000px rgba(0, 0, 0, 0.30);
  padding-bottom: 12px;


  :global{


    .active-link{
      background: var(--Gradient-white, linear-gradient(180deg, #FFF -56.58%, #D9D9D9 50%));
      color: var(--Brown-40, #262121);
      svg path{
        fill:  var(--Brown-40, #262121);
      }
    }
  }

}
